<template>
  <div class="emission-per-product">
    <div class="emission-per-product_label">{{ labelEmissions }}</div>
    <div v-if="!isSetCutoff" class="emission-per-product_label">{{ processEmissions[0] }}<div>{{ processEmissions[1] }}</div></div>
  </div>
</template>

<script>
import { formatBigNumberToString } from '@/utils/number'
export default {
  props: {
    isCalcTotalEmissions: {
      type: Boolean,
      default: false,
    },
    workflowData: {
      type: Object,
      default: () => {}
    },
    process: {
      type: Object,
      default: () => {}
    },
    boundary: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    labelEmissions() {
      return this.isCalcTotalEmissions ? this.labelBoundary : this.labelProcess;
    },
    labelBoundary() {
      return this.$t("cfp_emissions_product.label_emission_per_product")
    },
    labelProcess() {
      return this.process?.cut_off ? this.$t("cfp_emissions_product.title_cut_off") : this.$t("cfp_emissions_product.label_emission_per_product")
    },
    processEmissions() {
      let emissionsVal = this.process?.emission_processes === null ? '---' : formatBigNumberToString(this.process?.emission_processes, true);
      if (this.isCalcTotalEmissions) {
        emissionsVal = (this.boundary?.emission_boundaries === null || this.boundary?.processes.every(process => process.cut_off)) ? '---' : formatBigNumberToString(this.boundary?.emission_boundaries, true);
      }
      return [emissionsVal, `kg〜CO2e/${this.workflowData.product_unit}`];
    },
    isSetCutoff() {
      return this.isCalcTotalEmissions ? false : this.process?.cut_off;
    }
  }
}
</script>

<style lang="scss" scoped>
.emission-per-product {
  display: flex;
  flex-direction: column;
  padding: 0 8px;
  &_label {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: $monoBlack;
    word-break: break-all;
    display: flex;
    flex-flow: wrap;
  }
}
</style>