<template>
  <div class="custom-icon-wrap" :class="{ 'disabled': isDisabled }">
    <input v-model="valueModel" ref="exportFromRef" type="date" class="date custom-icon" :disabled="isDisabled"
      @input="handleDateChange" @click="handleOpenPickerSelect" />
    <div class="formatted-date" :class="{ 'placeholder': !valueModel, 'selected': valueModel }">
      {{ dateFormat }}
    </div>
    <img class="append-calender cursor-pointer" src="@/assets/icons/calendar.svg" alt=""
      @click.stop="handleOpenPickerSelect" />
  </div>
</template>

<script>
import { formatDate } from '@/utils/datetimeFormat';

export default {
  data() {
    return {
      valueModel: null,
      dateFormat: '',
    };
  },
  props: {
    inputValue: {
      type: [String, Number],
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    valueModel(newValue) {
      this.$emit('update:inputValue', newValue);
      this.dateFormat = newValue ? formatDate(newValue) : this.$t('contractor.placeholder_select_date');
    },
    "inputValue": {
      handler(newVal){
       this.valueModel = newVal
      }
    }
  },
  methods: {
    handleOpenPickerSelect() {
      if (this.isDisabled) return;
      this.$refs.exportFromRef.showPicker();
    },
    handleDateChange(event) {
      this.valueModel = event.target.value;
      this.dateFormat = this.valueModel ? formatDate(this.valueModel) : this.$t('contractor.placeholder_select_date');
    },
  },
  mounted() {
    this.valueModel = this.inputValue;
    this.dateFormat = this.valueModel ? formatDate(this.valueModel) : this.$t('contractor.placeholder_select_date');
  },
};
</script>

<style lang="scss" scoped>
.custom-icon-wrap {
  height: 40px;
  position: relative;
  background: $monoOffWhite;
  flex-grow: 1;
  display: flex;
  border-radius: 4px;
  box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044),
    0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
    0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351),
    0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
    0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);

  &:focus-within {
    border: 2px solid $blueMid !important;
  }

  .custom-icon {
    position: absolute;
    opacity: 0;
    z-index: 99;
    width: 100%;
    height: 100%;

    &:hover {
      cursor: pointer;
    }
  }

  .formatted-date {
    width: 100%;
    padding-left: 16px;
    line-height: 40px;
    color: $monoBlack;
    background: transparent;
    font-family: Source Han Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.03em;
    &.placeholder {
      color: $monoMid;
    }
  
    &.selected {
      color: $monoBlack;
    }
  }

  &:hover {
    background: $monoWhite;

    .formatted-date {
      color: $monoBlack !important;
    }
  }

  &.disabled {
    background: $monoLight !important;

    &:focus-within {
      border: unset !important;
    }

    &:hover {
      cursor: auto !important;
      .formatted-date.placeholder {
        color: $monoMid !important;
      }
    }

    .custom-icon {
      &:hover {
        cursor: auto;
      }
    }
  }
}

.append-calender {
  position: absolute;
  right: 10px;
  top: 25%;
  width: 20px;
  height: 20px;

  &:hover {
    cursor: pointer;
  }
}
</style>
