<template>
  <div
    class="cfp-title"
    :class=" (hiddenIfDoNotHaveAnyButton ? (!isFixedButton || !isShowListButtonInUi) : !isFixedButton) && 'smp-button-bottom'"
  >
    <div
      v-if="hiddenIfDoNotHaveAnyButton ? (isFixedButton && isShowListButtonInUi) : isFixedButton"
      class="cfp-title__content"
    >
      <div v-if="isDetailProduct && !isTemEmission" class="cfp-title__status">
        <span class="cfp-title__status__detail" :class="colorStatusDataDetails()" >{{statusDataDetails()}}</span>
      </div>
      <h2 class="cfp-title__title">{{ title }}</h2>
    </div>
    <div v-if="isShowListButtonInUi" class="cfp-title__btns" :class="{ 'fixed-button-bottom': isFixedButton }">
      <div class="cfp-title__btns__row">
        <button v-for="(button, index) in getListButtonGold" :key="index" :class="[button.class, button.action, { 'disabled': button.disabled }]" @click="handleClickBtn(button.action)">
          {{ $t(button.text) }}
        </button>
      </div>
      <div class="cfp-title__btns__row">
        <button v-for="(button, index) in getListButtonWhite" :key="index" :class="[button.class, button.action, { 'disabled': button.disabled }]" @click="handleClickBtn(button.action)">
          {{ $t(button.text) }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { STATUS_FIELD } from '@/constants/status';
import { getStatusNameById, getColorStatusById } from "@/utils/status";
import {mapState} from "vuex"
import { ROLE } from '@/constants/role';
export default {
  name: 'ProductCommonTitleAction',
  props: {
    title: {
      type: String,
      default: ''
    },
    isFixedButton: {
      type: Boolean,
      default: true
    },
    isDetailProduct: {
      type: Boolean,
      default: true
    },
    workflowData: {
      type: Object,
      default: () => {}
    },
    isApprovedData: {
      type: Boolean,
      default: false
    },
    isRegisterEmission: {
      type: Boolean,
      default: false
    },
    isHasData: {
      type: Boolean,
      default: false
    },
    isDisableUpdateApprovedEmissionData: {
      type: Boolean,
      default: false
    },
    hiddenIfDoNotHaveAnyButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('userData', ['currentUser']),
    showKeepButton() {
      return this.isNewRegisterProduct || this.isDetailProduct;
    },
    isTemEmission() {
      return this.workflowData?.is_tmp === 1
    },
    showDeleteButton() {
      return this.isNewRegisterProduct;
    },
    showSaveRegisterButton() {
      return this.isRegisterEmissions && !this.workflowData?.isReadOnlyDataTable;
    },
    showCancelRegisterButton() {
      return this.isRegisterEmissions;
    },
    isNewRegisterProduct() {
      return this.$route.name === "RegisterProduct"
    },
    isRegisterEmissions() {
      return this.$route.name === 'RegisterEmissions'
    },
    displayAsOwner() {
      return !!(
        this.workflowData?.is_owner &&
        (this.workflowData?.status === STATUS_FIELD.STATUS_SUBMIT || this.workflowData?.status === STATUS_FIELD.STATUS_CANCEL_SUBMMITED)
      );
    },
    displayAsAccepterCancelSubmitted() {
      return !!(
        this.workflowData?.status === STATUS_FIELD.STATUS_CANCEL_SUBMMITED &&
        (this.workflowData?.is_approver || this.currentUser?.user?.role_id === ROLE.ADMIN)
      );
    },
    getCurentRoleId() {
      return this.currentUser?.user?.role_id
    },
    // condition to show gold button for product infor
    isRoleApproverAndDontSelectToApproveData() {
      return this.getCurentRoleId === ROLE.APPROVAL && this.workflowData.is_approver < 1
    },
    isShowDraftButton() {
      return this.showKeepButton && ![STATUS_FIELD.STATUS_SUBMIT, STATUS_FIELD.STATUS_CANCEL_SUBMMITED].includes(this.workflowData?.status) && !this.isRegisterEmissions
    },
    isShowSubmitButton() {
      return this.workflowData?.status === STATUS_FIELD.STATUS_DRAFT
    },
    isShowApprovalButton() {
      return this.workflowData?.status === STATUS_FIELD.STATUS_SUBMIT && this.getCurentRoleId !== ROLE.USER && !this.isRoleApproverAndDontSelectToApproveData
    },
    isShowAllowCancleSubmitButton() {
      return this.displayAsAccepterCancelSubmitted && this.getCurentRoleId !== ROLE.USER && !this.isRoleApproverAndDontSelectToApproveData
    },
    // condition to show white button for product infor
    isShowDeleteButton() {
      return this.showKeepButton && ![STATUS_FIELD.STATUS_SUBMIT, STATUS_FIELD.STATUS_CANCEL_SUBMMITED].includes(this.workflowData?.status) && !this.isRegisterEmissions
    },
    isShowDuplicateButton() {
      return this.workflowData?.status === STATUS_FIELD.STATUS_DRAFT
    },
    isShowCancleSubmitButton() {
      return this.displayAsOwner && this.workflowData?.status === STATUS_FIELD.STATUS_SUBMIT
    },
    isApproveProductInfoInEmission() {
      return [STATUS_FIELD.STATUS_APPROVED, STATUS_FIELD.STATUS_REPRENSENT_APPROVED].includes(this.workflowData?.product_status)
    },
    // condition to show gold button for emissions
    isShowButtonSaveDraftEmission() {
      return this.isTemEmission ||
      ([STATUS_FIELD.STATUS_DRAFT,
        STATUS_FIELD.STATUS_REPRENSENT_RETURN,
        STATUS_FIELD.STATUS_RETURN].includes(this.workflowData?.status) &&
      this.workflowData?.is_tmp === 0 && this.workflowData?.is_owner === 1) ||
      (this.isApproveProductInfoInEmission && this.getCurentRoleId === ROLE.ADMIN &&
      [STATUS_FIELD.STATUS_APPROVED, STATUS_FIELD.STATUS_REPRENSENT_APPROVED].includes(this.workflowData?.status))
    },
    isShowSubmitProductButton() {
      return this.isApproveProductInfoInEmission &&
      [STATUS_FIELD.STATUS_DRAFT].includes(this.workflowData?.status) &&
      this.workflowData?.is_tmp === 0 && this.workflowData?.is_owner === 1;
    },
    isShowApprovalEmissionButton() {
      return this.isApproveProductInfoInEmission &&
      this.workflowData?.status === STATUS_FIELD.STATUS_SUBMIT &&
      this.isAdminOrApprover
    },

    // condition to show white button for emissions
    isShowDeleteEmissionButton() {
      return this.isTemEmission ||
      ([STATUS_FIELD.STATUS_DRAFT,
        STATUS_FIELD.STATUS_REPRENSENT_RETURN,
        STATUS_FIELD.STATUS_RETURN].includes(this.workflowData?.status) &&
      this.workflowData?.is_tmp === 0 && this.workflowData?.is_owner === 1)
    },
    isShowDuplicateEmissionButton() {
      return this.workflowData?.status === STATUS_FIELD.STATUS_DRAFT && this.workflowData?.is_tmp === 0 && this.workflowData?.is_owner === 1
    },
    isShowCancleSubmitEmissionButton() {
      return this.displayAsOwner && this.workflowData?.status === STATUS_FIELD.STATUS_SUBMIT && this.isApproveProductInfoInEmission
    },
    isShowAllowCancleSubmitEmissionButton() {
      return this.isApproveProductInfoInEmission &&
      this.workflowData?.status === STATUS_FIELD.STATUS_CANCEL_SUBMMITED &&
      this.isAdminOrApprover
    },
    isDisableSubmitEmissionButton() {
      return this.workflowData?.product_emissions === "-";
    },
    isAdminOrApprover() {
      return this.getCurentRoleId === ROLE.ADMIN || (this.getCurentRoleId === ROLE.APPROVAL && this.workflowData?.is_approver === 1);
    },
    getListGoldButtonForProductInforByStatusAndRole() {
      const buttonList = [];

      //check condition to push draft button
      if(this.isShowDraftButton) {
        buttonList.push({
          class: 'btn-goldMid',
          text: 'b_register_product.button_keep',
          action: 'clickSaveDraftBtn',
          disabled: this.isHasData
        })
      }

      //check condition to push submit button
      if(this.isShowSubmitButton) {
        buttonList.push({
          class: 'btn-goldMid',
          text: 'register_data.button_application',
          action: 'clickSubmitBtn'
        })
      }

      //check condition to push approve button
      if(this.isShowApprovalButton) {
        buttonList.push({
          class: 'btn-goldMid', 
          text: 'cfp_emissions_product.button_approve_product',
          action: 'clickApproveBtn'
        })
      }

      //check condition to push cancel submit button
      if(this.isShowAllowCancleSubmitButton) {
        buttonList.push({
          class: 'btn-goldMid',
          text: 'register_data.button_accept_cancel_request',
          action: 'clickAllowCancelSubmitProductBtn'
        })
      }

      if (this.showSaveRegisterButton) {
        buttonList.push({
          class: 'btn-goldMid', 
          text: 'cfp_emissions_product.button_save_register_emissions_product',
          action: 'clickSaveEmissionsBtn',
          disabled: !this.isHasData
        })
      }

      if(this.isApprovedData) {
        return []
      }
      return buttonList
    },
    getListWhiteButtonForProductInforByStatusAndRole() {
      const buttonList = [];

      if(this.isShowDeleteButton) {
        buttonList.push({
          class: 'btn-monoWhite',
          text: 'b_register_product.button_delete',
          action: 'clickCancelSaveDraftBtn'
        })
      }

      if (this.isShowDuplicateButton) {
        buttonList.push({
          class: 'btn-monoWhite',
          text: 'cfp_emissions_product.button_duplicate_product',
          action: 'clickDuplicateProductBtn'
        })
      }

      if(this.isShowCancleSubmitButton) {
        buttonList.push({
          class: 'btn-monoWhite',
          text: 'cfp_emissions_product.button_cancel_submit_product',
          action: 'clickCancelSubmitProductBtn'
        })
      }

      if (this.isRegisterEmissions) {
        buttonList.push({
          class: 'btn-monoWhite',
          text: 'cfp_emissions_product.button_cancel_register_emissions_product',
          action: 'clickCancelSaveEmissionsBtn'
        })
      }

      if (this.workflowData?.status === STATUS_FIELD.STATUS_CANCEL_SUBMMITED) {
        return []
      }
      if(this.isApprovedData) {
        return []
      }

      return buttonList
    },
    getListGoldButtonForProductEmissionsByStatusAndRole() {
      const buttonList = [];
      if(this.isShowButtonSaveDraftEmission) {
        buttonList.push({
          class: 'btn-goldMid',
          disabled: this.isDisableUpdateApprovedEmissionData || this.isHasData,
          text: 'b_register_product.button_keep',
          action: 'clickSaveDraftEmissionsBtn'
        })
      }

      if(this.isShowSubmitProductButton) {
        buttonList.push({
          class: 'btn-goldMid',
          text: 'register_data.button_application',
          action: 'clickSubmitProductBtn',
          disabled: this.isDisableSubmitEmissionButton
        })
      }

      if(this.isShowApprovalEmissionButton) {
        buttonList.push({
          class: 'btn-goldMid', 
          text: 'cfp_emissions_product.button_approve_product',
          action: 'clickApproveEmissionBtn'
        })
      }

      if(this.isShowAllowCancleSubmitEmissionButton) {
        buttonList.push({
          class: 'btn-goldMid',
          text: 'register_data.button_accept_cancel_request',
          action: 'clickAllowCancelSubmitEmissionBtn'
        })
      }

      return buttonList
    },
    getListWhiteButtonForProductEmissionsByStatusAndRole() {
      const buttonList = [];

      if(this.isShowDeleteEmissionButton) {
        buttonList.push({
          class: 'btn-monoWhite',
          text: 'b_register_product.button_delete',
          action: 'clickCancelSaveDraftEmissionsBtn',
        })
      }

      if (this.isShowDuplicateEmissionButton) {
        buttonList.push({
          class: 'btn-monoWhite',
          text: 'cfp_emissions_product.button_duplicate_product',
          action: 'clickDuplicateEmissionsBtn',
        })
      }

      if(this.isShowCancleSubmitEmissionButton) {
        buttonList.push({
          class: 'btn-monoWhite',
          text: 'cfp_emissions_product.button_cancel_submit_product',
          action: 'clickCancelSubmitEmissionBtn'
        })
      }

      if(this.isApprovedData && this.isApproveProductInfoInEmission) {  // case product & emission have status approve
        return []
      }

      return buttonList
    },
    getListButtonGold() {
      return !this.isRegisterEmission ? this.getListGoldButtonForProductInforByStatusAndRole : this.getListGoldButtonForProductEmissionsByStatusAndRole;
    },
    getListButtonWhite() {
      return !this.isRegisterEmission ? this.getListWhiteButtonForProductInforByStatusAndRole : this.getListWhiteButtonForProductEmissionsByStatusAndRole
    },
    isShowListButtonInUi() {
      return this.getListButtonGold.length !== 0 || this.getListButtonWhite.length !== 0;
    },
  },
  methods: {
    handleClickBtn(key) {
      this.$emit('handleActionByName', key);
    },
    statusDataDetails() {
      const statusId = this.workflowData?.status ?? this.workflowData?.status_product_emissions;
      let status = getStatusNameById(statusId);
      if(this.$route.query?.type === 'approval' && statusId === STATUS_FIELD.STATUS_SUBMIT ) {
        status = getStatusNameById(STATUS_FIELD.STATUS_SUBMIT_NON_USER);
      }

      if(this.$route.query?.type === 'approval' && statusId === STATUS_FIELD.STATUS_CANCEL_SUBMMITED ) {
        status = getStatusNameById(STATUS_FIELD.STATUS_CANCEL_SUBMMITED_NON_USER);
      }

      return status;
    },
    colorStatusDataDetails() {
      const statusId = this.workflowData?.status ?? this.workflowData?.status_product_emissions;
      
      if(this.$route.query?.type === 'approval' && statusId === STATUS_FIELD.STATUS_SUBMIT ) {
        return `color-${getColorStatusById(STATUS_FIELD.STATUS_SUBMIT_NON_USER)}`;
      }

      if(this.$route.query?.type === 'approval' && statusId === STATUS_FIELD.STATUS_CANCEL_SUBMMITED ) {
        return `color-${getColorStatusById(STATUS_FIELD.STATUS_CANCEL_SUBMMITED_NON_USER)}`;
      }

      return `color-${getColorStatusById(statusId)}`;
    },
  },
}
</script>

<style lang="scss" scoped>
.button-base {
  &:hover {
    background: $goldLight;
    color: $monoWhite;
  }

  &:active {
    border: 2px solid $blueMid;
    padding: 5px 14px 7px 14px;
  }
}

.cfp-title {
  display: flex;
  padding: 40px 20px 48px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  align-self: stretch;
  margin: 0 -20px;

  &__content {
    display: flex;
    gap: 24px;
    flex-flow: column;
  }

  &__title {
    color: $goldMid;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 1.1px;
  }
  &__status {
    &__detail {
      font-family: "Source Han Sans JP";
      font-size: 11px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0.33px;
      padding: 2px 8px 3px 8px;
      &.color-blue {
        color: $blueDark2;
        border: 1px solid $blueDark2;
      }
      &.color-red {
        color: $redMid;
        border: 1px solid $redMid;
      }

      &.color-light-green {
        color: $goldMid;
        border: 1px solid $goldMid;
      }
      &.color-mono-black {
        color: $monoBlack;
        border: 1px solid $monoBlack;
      }
    }
  }

  &__btns {
    display: flex;
    align-items: center;
    gap: 20px;
    background: $bgMid;
    display: flex;
    flex-flow: column;
    width: 100%;
    padding: 16px 20px;

    &__row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 8px;
      button {
        display: flex;
        padding: 7px 16px 9px 16px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        box-shadow: 0px 36px 33px 0px rgba(160, 181, 186, 0.07), 0px 13.141px 12.046px 0px rgba(160, 181, 186, 0.11), 0px 6.38px 5.848px 0px rgba(160, 181, 186, 0.13), 0px 3.127px 2.867px 0px rgba(160, 181, 186, 0.17), 0px 1.237px 1.134px 0px rgba(160, 181, 186, 0.24);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.42px;
        width: 100%;
    
        &.btn-goldMid {
          background: $goldMid;
          color: $monoWhite;
          @extend .button-base;
          &.disable-btn {
            background: $monoLight;
            color: $monoMid;
            pointer-events: none;
          }
        }
    
        &.btn-monoWhite {
          background: $monoOffWhite;
          color: $monoBlack;
          @extend .button-base;
        }
        &.disabled {
          pointer-events: none;
          cursor: default;
          color: $monoMid;
          background: $monoLight;
        }
      }
    }

    @media (max-width: 1023px) {
      &.fixed-button-bottom {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 99;
        top: auto;
      }
    }
  }

  &.smp-button-bottom {
    padding: 0px;
    margin-bottom: -80px;
  }
}

@media (min-width: 1024px) {
  .cfp-title {
    flex-direction: row;
    justify-content: space-between;
    gap: 48px;
    padding: 40px 40px 48px 40px;
    margin: unset;

    &__btns {
      background: none;
      display: flex;
      flex-flow: row;

      &__row {
        width: auto;
        flex-direction: row;
        button {
            width: auto;
            min-width: 75px;
        }
      }

      &.fixed-button-bottom {
        width: auto;
        gap: 8px;
        padding: 0;
      }
    }
  }
}
</style>