<template>
  <div>
    <v-dialog
      v-model="dialogSubmitData"
      @click:outside="handleClickOutSite"
      ref="dialog"
      max-width="464px"
      :content-class="contentClassPopup"
    >
      <v-card>
        <v-card-title v-show="isShowUserApproval">
          <span>{{ $t('register_data.title_application_approval') }}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="approvalForm" class="submit-popup-form" @submit.prevent="submitHandler">
            <div v-show="isShowUserApproval" class="input-select">
              <v-select
                id="select-approver"
                :items="listApprovers"
                item-text="name"
                item-value="id"
                v-model="approvalFormData.selected_approver"
                :label="$t('list_menu.placeholder_please_select')"
                :placeholder="$t('list_menu.placeholder_please_select')"
                dense
                solo
                hide-details
                ref="approver"
                :menu-props="{ contentClass: 'selecting select-durations pulldown-year' }"
                :rules="isShowUserApproval ? requiredRule : []"
                :class= "{'border-error': borderError}"
            ></v-select>
            <div class="error-message" v-if="showMessage">{{ $t('validation.error_required_field') }}</div>
            </div>
            <v-divider v-show="isShowUserApproval && isShowTitle"></v-divider>
            <div v-show="isShowTitle" class="input-common">
              <div class="input-label">{{ $t('register_reduction.label_input_title') }}</div>
              <InputField :label="$t('register_data.placeholder_title')" v-model="approvalFormData.title" :inputValue="approvalFormData.title" id="submit-title" />
              <div class="input-description">{{ $t('register_data.description_register_data') }}</div>
            </div>
            <div><slot></slot></div>
          <v-divider></v-divider>

          </v-form>
          <div class="dialog-comment">
            <div class="input-label">{{ $t('popup.title_log_confirm') }}</div>
            <v-textarea
              no-resize
              v-model="approvalFormData.comment"
              hide-details
            ></v-textarea>
          </div>
        </v-card-text>
        <v-card-actions>
        <common-button class="left-button-popup" @action="cancelDialog" :label="$t('popup.button_cancel')"/>
        <common-button type="redColor" @action="submitHandler" :label="$t('register_data.button_application')" id="submit-btn-popup"/>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import CommonButton from '@/components/utils/button.vue';
import InputField from '@/components/products/input/InputField.vue';
import { mapState } from 'vuex';
export default {
  components: { CommonButton, InputField },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    listApprovers: {
      type: Array,
      default: () => [],
    },
    isLoadingSubmitting: {
      type: Boolean,
      default: false,
    },
    dataPattern: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: '',
    },
    isShowTitle: {
      type: Boolean,
      default: true,
    },
    isShowUserApproval: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      boxWidth: 0,
      resizeTimer: null,
      showMessage: false,
      approvalFormData: {
        selected_approver: null,
        comment: '',
        title: null,
      },
      borderError: false,
      requiredRule: [(valueInput) => !!valueInput || 'この項目は入力が必須です。'],
    };
  },
  mounted() {
    this.approvalFormData.title = this.dataPattern?.workflow_data?.title || sessionStorage.getItem('title') || this.title;
  },
  computed: {
    ...mapState('userData', ['language', 'currentUser']),
    dialogSubmitData: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('update:dialog', value);
      },
    },
    isEsLanguage() {
      return ['es', 'vi'].includes(this.language.code);
    },
    contentClassPopup() {
      let classText = 'reset-padding-card-text';
      classText = this.isEsLanguage ? `${classText} submit-form` : classText;
      return classText;
    }
  },
  watch: {
    title: {
      handler(value) {
        this.approvalFormData.title = value;
      }
    },
    'approvalFormData.selected_approver' (val) {
      if(val) {
        this.showMessage = false;
        this.borderError = false;
      }
    }
  },
  methods: {
    cancelDialog() {
      this.$emit('closeDialogSubmitData');
    },
    submitHandler() {
      if(this.$refs['approver'].hasError) {
        this.showMessage = true;
        this.borderError = true;
      }
      if (this.$refs.approvalForm.validate()) {
        this.$emit('submitHandler', {
          ...this.approvalFormData,
          selected_approver: this.isShowUserApproval
            ? this.approvalFormData.selected_approver
            : this.currentUser?.user?.id,
        });
        // this.$refs.approvalForm.reset();
      }
    },
    submit() {
      this.$emit('submit');
    },
    handleClickOutSite() {
      this.$emit('closeDialogSubmitData');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/components/popup/index.scss';
</style>
<style lang="scss">
.submit-popup-form {
  input, .v-text-field__slot label {
    font-size: 14px !important;
  }
  .v-icon {
    &.mdi-menu-down {
      color: $monoDusty;
      &.primary--text {
        color: $monoDark !important;
      }
    }
  }
  .input-select {
    .v-input--hide-details:focus-within  {
      outline: none;
    }
    .v-input--hide-details:hover {
      .mdi-menu-down {
        color: $monoDark !important;
      }
    }
  }
  .v-input--has-state {
    .error--text {
      color: $monoDark !important;
    }
  }
  .border-error {
    border: 2px solid $redMid;
  }
  .text-custom {
    padding: 26px 32px;
    color: $monoDark;
    font-family: "Source Han Sans JP";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 184.615% */
    letter-spacing: 0.65px;
  }
}
</style>
<style lang="scss" scoped>
.v-input.v-text-field.submit-title-input {
  ::v-deep .v-input__control > .v-input__slot {
    box-shadow: 0px 2px 6px 0px rgba(160, 181, 186, 0.50) inset, 0px 2px 2px 0px rgba(160, 181, 186, 0.15) inset;
    padding-left: 16px;
    padding-right: 0;
    .v-input__append-inner {
      padding: 0;
      .v-input__icon.v-input__icon--clear {
        opacity: 1;
        min-width: 16px;
        width: 16px;
        height: 16px;
        margin: 12px 11px;
      }
    }
  }
}
</style>