import { render, staticRenderFns } from "./EmissionPerProduct.vue?vue&type=template&id=9d617a80&scoped=true&"
import script from "./EmissionPerProduct.vue?vue&type=script&lang=js&"
export * from "./EmissionPerProduct.vue?vue&type=script&lang=js&"
import style0 from "./EmissionPerProduct.vue?vue&type=style&index=0&id=9d617a80&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9d617a80",
  null
  
)

export default component.exports