import { formatBigNumber } from '@/concerns/newRegisterData/wijmo.helper';
import { addThoundSandCommaWithTwoDigit} from '@/utils/convertNumber';

export const formatBigNumberToString = (num, isAlowMore25digit = false) => {
  if(num === '-') {
    return '---'
  }
  if(num === 0 || num === '0') {
    return '0.00'
  }
  return addThoundSandCommaWithTwoDigit(formatBigNumber(num, 35).replace(/[^0-9\.\-]+/g, ''), '', false, isAlowMore25digit);
}